import Layout from '../../components/layout'
import React from 'react'
import SEO from '../../components/seo'
import { graphql } from 'gatsby'
import ServiceHeader from '../../components/sections/ServiceHeader'
import { StaticImage,GatsbyImage,getImage  } from 'gatsby-plugin-image'
import HeadingContentIconBoxes from '../../components/sections/HeadingContentIconBoxes'
import { default as SbsIHCB } from '../../components/sections/SidebysideImageHeadingContentButton'
import CaseStudy from '../../components/sections/CaseStudy'
//import SwiperAccordion from '../../components/SwiperAccordion'
import CallToAction from '../../components/sections/CallToAction'
import FAQ from '../../components/sections/Faq'
export default function GoogleAdwordsPage(props) {
  const featuredWork = props.data.featuredWork.edges[0].node;
  const featuredWorkImage = getImage(featuredWork.banner)
  return (
    <>
      <Layout>
        <SEO
          title="#1 Search Engine Marketing Kuala Lumpur | PPC Management Agency  | Majiska"
          description=" Search Engine Marketing Kuala Lumpur. Realistic. Transparent. Result Driven. Our point-to-point action ensures that you make the best digital marketing decisions for your business. "
          isCollectionPage={true}
          about={{ type: 'service' }}
          service={{
            name: 'Google Ads',
            sameAs: 'https://www.wikidata.org/wiki/Q271982',
            description: "How did we achieve mastery of the science of Search Engine Marketing? Inquire with our Google Premier Partner-certified experts at our Kuala Lumpur agency's headquarters."
          }}
          faq={[
            {
              q: "What is  Search Engine Marketing?",
              a: "Your top four listings in search engines will have paid advertising slots with a smaller “Ad” logo will appear in the place next four lines of results. PPC advertising is also known as Pay Per Click since advertisers only pay when someone clicks on the ad."
            },
            {
              q: "How much does Google Ads cost?",
              a: "Majiska’s status as a Google Premier Partner requires it to maintain annual certifications and qualifications. Additionally, Google supports and audits our Google Ads accounts on a continuous basis to ensure best practises are followed."
            },
            {
              q: "What stops competitors clicking my ads?",
              a: "Majiska uses a 3rd party app to personalise the security for each of our client's accounts as well to combat fraud. This software blocks users from seeing advertisements based on their behaviour and location. "
            }
          ]}
        />
        <main className="">
          <ServiceHeader
            heading="Search Engine Marketing"
            subheading="How did we achieve mastery of the science of  Search Engine Marketing? Inquire with our Google Premier Partner-certified experts at our Kuala Lumpur agency's headquarters."
            list={[
              {
                iconName: 'handshake',
                description:
                  "We are your digital success partner that takes care of your business growth",
              },
              {
                iconName: 'brain',
                description:
                  "7 of Malaysia's leading digital performance specialists in one local agency.",
              },
              {
                iconName: 'strategy-action',
                description:
                  "Trusted by Malaysia's leading brands, but proudly partnering many smaller ones with big aspirations",
              },
              {
                iconName: 'certified',
                description:
                  "Kuala Lumpur’s leading independent Google Partner (accredited by Google).",
              },
            ]}
            image={
              <StaticImage src="../../images/malaysia-top-google-adwords-ads-expert.png" alt="A dinosaur" />
            }
            isImageSquare
          />

          <HeadingContentIconBoxes
            heading="Why Majiska for Search Engine Marketing?"
            content={`<p>The most reliable and consistent amongst all channels - Google Ads, (formerly Google Adwords). However, this is accurate only if Google Ads management is handled properly – as we do as a Google Premier Partner agency. Otherwise, you're going to waste both your budget and your business online reputation.</p>
            <p>We approach Google Ads management using a hallmark accuracy and authenticity that upholds our agency's reputational integrity through the assistance of modern AI tech. Coupled with industry's most brilliant digital marketing experts scrutinising every step. This ensures your ROI and bottom line is taken care of.</p>
            <p>We are proud of our successful campaigns that are also ethical. Both small and large businesses are seeing benefits from using our Google Ads management, so will you.</p>`}
            iconBoxStyle={2}
            iconBoxObj={[
              {
                name: 'Google Ads',
                description:
                  "Accelerate the growth of your website's targeted traffic with PPC (Pay-Per-Click) advertisements on the Google Ads search engine platform.",
                iconName: 'google-ads',
              },
              {
                name: 'Display Ads',
                description:
                  'Place ads that encourage viewers to look at, entice them to click, and keep them on your website.',
                iconName: 'display-ads',
              },
              {
                name: 'Google Shopping',
                description:
                  'With Google Shopping, you can advertise your items, increase traffic to your website, and make money.',
                iconName: 'shopping',
              },
              {
                name: 'Remarketing',
                description:
                  'Audience retargeting via Google Display Network, Facebook, LinkedIn, and etc, helps you to target consumers and have a continued impact on brand recognition, allowing you to keep your audience updated and getting them back to your website.',
                iconName: 'remarketing',
              },
            ]}
            padding="py-0"
          />
          <SbsIHCB
            heading="Home to the best Search Engine Marketing specialists"
            content={
              <p>
                We pride ourselves on our Google Ad certified professionals. Our Google-accredited Ads experts are equipped with the most advanced technology that enables us to offer - and you to have - greater advertising potential.
              </p>
            }
            image={
              <StaticImage src="../../images/malaysia-top-digital-marketing-specialists.png" alt="A dinosaur" />
            }
            isEven
            isImageSquare
            padding={`py-0`}
          />
          <HeadingContentIconBoxes
            heading="Our approach to Search Engine Marketing."
            content={`There’s always a better way! We are continuously experimenting with ad messages, keywords, landing pages, and bidding models in order to maximise the effectiveness of any marketing budget.`}
            iconBoxStyle={3}
            iconBoxObj={[
              {
                name: 'Continuous Experimentation',
                description:
                  'There’s always a better way! We are continuously experimenting with ad messages, keywords, landing pages, and bidding models in order to maximise the effectiveness of any marketing budget.',
                iconName: 'experiment',
              },
              {
                name: 'Local-focused Targeting',
                description:
                  'We target users based on their intention or geographical location through keyword and pin-point targeting.',
                iconName: 'local',
              },
              {
                name: 'Results That Can Be Measured',
                description:
                  'Determine campaign profitability through advanced analytics that provides transparency of ads activities.',
                iconName: 'measure',
              },
              {
                name: 'Machine Learning Integration',
                description:
                  'Our ethical and organic SEO tactic ensures that we keep your site on the top of Google\'s search results.',
                iconName: 'ai',
              },
              {
                name: 'Target Market',
                description:
                  'We conduct market research on your company, consumers, and competitors to establish your ideal target audience.',
                iconName: 'audience',
              },
              {
                name: 'Best Practice Optimisation',
                description:
                  'By keeping updated with platform changes and utilising industry-leading strategies, we are able to maximise leads whilst reducing costs.',
                iconName: 'architecture',
              },
              {
                name: 'Class-Leading Goal Tracking',
                description:
                  'A significant amount of time and money is dedicated to measure valuable actions, which ensures all marketing platforms are evaluated and accountable.',
                iconName: 'target',
              }
            ]}
          />
          <CaseStudy
            title={featuredWork.title}
            content={featuredWork.description}
            image={
              <GatsbyImage image={featuredWorkImage} className="stats__fimage" alt={`case study of ${featuredWork.title}`} />
            }
            to={`/work/${featuredWork.slug}`}
            stats={featuredWork.stats}
          />
          <SbsIHCB
            heading="Transparency and accountability."
            content={
              <>
                <h3 className="">Goal and results you can see in your bottom line.</h3>
                <p>
                  Visibility and CTR aren't our only results; there's a target; both offer visible commercial and financial as well as well as monetary returns. Our team in Kuala Lumpur will boost your business return on investment by improving the advertising efficiency further.
                  </p>
                <h3 className="">Performance you can monitor yourself.</h3>
                <p>
                  Data and analytics are at the soul of our digital marketing scientific approach. With a ROI calculator and Performance Forecasting Tool, our performance tracking platform brings leading analytics into your fingertips and provides complete transparency for Google Ads.</p>
                <h3 className="">Better technology, better data, and better outcomes.</h3>
                <p>
                  Our Google Ads specialists will give you a deeper understanding of the market by utilising the best artificial intelligence (AI), machine learning, predictive data analysis and process automation. And deeper, more accurate insights lead to better campaign results and more rewarding ROI.</p>
              </>
            }
            image={
              <StaticImage src="../../images/malaysia-best-digital-marketing-company.png" alt="A dinosaur" />
            }
            isImageSquare
            isEven={true}
            padding="py-resp-16-24 lg:pt-0"
          />
          <SbsIHCB
            heading="The benefits of smart Search Engine Marketing management."
            content={
              <>
                <p>
                  Our proprietary data-driven Google Ads approach blends the best advertising minds and cutting-edge technology to achieve superior performance across the board.
                </p>
                <ul>
                  <li>Attract a wide audience</li>
                  <li>Stay ahead of of your competitors</li>
                  <li>Increase the number of eligible leads created</li>
                  <li>Enhance brand recognition</li>
                  <li>Complement the latest digital and conventional advertising efforts</li>
                </ul>
              </>
            }
            isImageSquare
            image={
              <StaticImage src="../../images/malaysia-digital-marketing-strategy-partner.png" alt="A dinosaur" />
            }
            padding="py-0"
          />
          <SbsIHCB
            className={``}
            heading={
              <>
                The importance of a reputable Search Engine Marketing partner.
              </>
            }
            content={
              <>
                <p>
                  Google Ads campaign management that is intelligent, scientific, and ethical is critical if you want to maximise your budget and improve your online reputation.
                </p>
              </>
            }
            isFull={true}
            
          />
          <HeadingContentIconBoxes
            heading="Are we right for you?"
            content={`Consider our Search Engine Marketing case studies. As you can see, we've assisted clients of all sizes and locations in Malaysia in achieving their digital marketing and business objectives. And if you have well-defined goals, we can help you achieve those.`}
            iconBoxStyle={3}
            iconBoxObj={[
              {
                name: 'Micro',
                description:
                  'Our smaller clients account for 28% of businesses with one to ten staff and are widely regarded as having a growth mentality.',
                iconName: 'micro',
              },
              {
                name: 'Small',
                description:
                  'We identify 25% of our clients as small companies with 11–50 employees.',
                iconName: 'small',
              },
              {
                name: 'Medium',
                description:
                  'We identify 25% of our clients as medium-sized firms with 50–100 employees.',
                iconName: 'medium',
              },
              {
                name: 'Enterprise',
                description:
                  '22% of our customers are larger companies with more than 100 employees.',
                iconName: 'enterprise',
              }
            ]}
            padding="py-0"
          />
          {/* <SwiperAccordion

            content={[
              {
                name: 'COOL',
                content:
                  '<p>Sed augue ipsum,<br> egestas nec, vestibulum et, malesuada adipiscing, dui. Phasellus dolor. Vivamus elementum semper nisi.</p>',
                image: 'imageSEO',
              },
              {
                name: 'Digital Advertising',
                content:
                  'Vivamus euismod mauris. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Praesent congue erat at massa.',
                image: 'imagePPC',
              },
              {
                name: 'Google Ads',
                content:
                  'Aliquam eu nunc. Donec sodales sagittis magna. Nunc sed turpis. Vivamus quis mi.',
                image: 'imageSocial',
              },
              {
                name: 'Remarketing',
                content:
                  'VEtiam ut purus mattis mauris sodales aliquam. Mauris turpis nunc, blandit et, volutpat molestie, porta ut, ligula. Nam commodo suscipit quam. Aliquam lobortis.',
                image: 'imageRemarketing',
              },
            ]}
            className={`pb-resp-16-32 bg-theme`}
          /> */}
          <FAQ
            content={[
              {
                q: "What is Search Engine Marketing?",
                a: "<p>Ads in their most basic form show up at the top of search engines such as Google or Bing's search results. Also, for example, if you were to search for “Search Engine Marketing Kuala Lumpur,” your top four listings will contain Google Ads-related advertising with a smaller “Ad” logo will appear in the place next four lines of results. PPC advertising is also known as Pay Per Click since advertisers only pay when someone clicks on the ad.</p><p>The order of these ads is based on the factors in-multiple processes, which is affected by many variables that are measured during each and every search. The prime factors are:A)  The cost per click you're willing to pay;B)  The level of your search relevancy of your advertisement and web page.</p><p>Additionally, this calculation is affected by variables such as page load speed and the number of times your ad has been clicked previously.</p><p>The advertisements can appear in several different forms, including phone numbers, specials, and images of the specific products. The variations here are affected by various Ad Extensions and different Ad Types, advertisers may aim for in order to catch searchers' attention.</p>",
              },
              {
                q: "How much does Google Ads cost?",
                a: "<p>Google has created a product that enables business owners to manage their own Google AdWords accounts. Although managing your own advertising account is not easy or convenient, it is possible. Many businesses that offer Google AdWords are unqualified to handle an advertiser's marketing expenditure.</p><p>A good analogy would be for Accountants, being an accredited is a choice, not a requirement.  Do you have the trust in giving someone control of your money to someone who has no qualification?</p><p>Majiska’s status as a Google Premier Partner requires it to maintain annual certifications and qualifications. Additionally, Google supports and audits our Google Ads accounts on a continuous basis to ensure best practises are followed.</p><p>Premier Partners also get access to excellent support and earlier access to new features,  as well as regular training from Google, giving them an edge over their rivals.</p>",
              },
              {
                q: "What stops competitors clicking my ads?",
                a: "<p>There are many mechanisms employed by Google to protect against ad fraud, including results being altered based on a user's past search activities and behaviours. Of course, even with these protections in place, a malicious attacker is able to overcome these, which could cost advertisers a substantial amount of money from fraudulent clicks.</p><p>Majiska uses a 3rd party app to personalise the security for each of our client's accounts as well to combat fraud. This software blocks users from seeing advertisements based on their behaviour and location. After implementation, we've recorded annual savings of hundreds of thousands of dollars in wasted clicks.</p>"
              }
            ]} />
          <CallToAction />
        </main>
      </Layout>
    </>
  )
}


export const pageQuery = graphql`
{
  featuredWork: allContentfulPastWorks(
    filter:{markAsFeatured: {eq: true}}
  ) {
    edges {
      node {
        id
        title
        slug
        description
        date: createdAt(formatString: "MMMM DD, YYYY")
        banner: bannerImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        stats{
          number,
          description,
        }
      }
    }
  }
}
`
