import * as React from "react"
import { useRef, useState, useEffect } from "react"
interface IFAQ {
  q: string
  a: string
}

interface AccordionProps {
  isCurrent: boolean
  onClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
  qa: IFAQ
}
export default function Accordion({
  qa,
  isCurrent,
  onClickHandler,
}: AccordionProps) {
  const [panelHeight, setPanelHeight] = useState<number>(0)
  const ref = useRef(null)
  useEffect(() => {
    setPanelHeight(ref.current.clientHeight)
  }, [])
  return (
    <li className="service__accordion-group">
      <button
        className={`accordion-group__trigger ${isCurrent ? "is--open" : ""} `}
        onClick={onClickHandler}
      >
        <span className="text"> {qa.q}</span>
        <span className="chevron">
          <svg
            className="fill-current text-white w-4 h-auto object-contain"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 256 256"
          >
            <g>
              <g>
                <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093   " />
              </g>
            </g>
          </svg>
        </span>
      </button>
      <div
        className={`accordion-group__panel ${isCurrent ? "is--open" : ""}`}
        style={{ maxHeight: isCurrent ? panelHeight : 0 }}
      >
        <div
          ref={ref}
          className="content  pt-resp-5-8 pb-resp-6-14 px-resp-5-8"
          dangerouslySetInnerHTML={{ __html: qa.a }}
        />
      </div>
    </li>
  )
}
