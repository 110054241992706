import * as React from 'react'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import Accordion from '../Accordion';
import { IPadding } from './SidebysideImageHeadingContentButton';

interface IFAQ {
    q: string;
    a: string;
}
interface FAQProps extends IPadding {
    content: IFAQ[]
}
export default function FAQ({ content,padding }: FAQProps) {

    const [currAccordion, setCurrAccordion] = useState<number>(0)
    function toggleVisibility(i: number): void {
        (currAccordion != i) && (setCurrAccordion(i));
    }

    return (
        <>
            <section className={`section--faq ${padding ? padding : 'py-resp-16-32'}`}>
                <div className="container--padded">
                    <div className="max-w-5xl mx-auto">
                        <h3 className="text-4xl leading-none">
                            Frequently Asked Questions
                    </h3>
                        <ul className="accordion accordion--services -mx-4 lg:mx-0">
                            {content.map((qa, i) => {
                                return (
                                    <Accordion key={i} isCurrent={currAccordion === i} qa={qa} onClickHandler={() => toggleVisibility(i)} />
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}